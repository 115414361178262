import React, { Component } from 'react';
import ProfilePage from "./ProfilePage";

class App extends Component {
  render() {
    return (
      <div>
        <ProfilePage/>
      </div>
    );
  }
}

export default App;
